<template >
  <div class="about">
    <div class="header">
      <Parallax
        :speed="-.5"
        parallaxType="Offset"
        style="position:relative; top:0; right:0; display:block; height:100%; display:block;"
        :marged="false"
        :masked="true"
        :skewed="false"
        :autosize="true"
        :rotate="true"
        :degree="0"
        :minMax="true"
      >
        <v-img :src="headerUiUx" :contain="false" :height="((isMobile)?20:40)+'vh'" />
      </Parallax>
    </div>
    <v-container style="padding-bottom:140px;">
      <div class="article_content">
        <h1 class="styloText">I ❤️ Design</h1>
        <br />
        <p class="styloText first_paragraph">
          <b>
            C'est avec une conscience en histoire de l'art, un savoir appliqué en principes mathématiques régissant
            l'esthétisme au sens commun et un regard aiguisé que l'objet prend forme.
          </b>
          <br />J'aime
          <span class="surligneur">travailler avec conviction et en étroite collaboration</span> pour élaborer et créer les objets visuels constituantes
          de nouvelles images de marque singulières.
          <br />De Leonard De Vinci à Banksy en passant par Mirò et Picasso, l'art pictural a définit le sens de la forme à travers les siècles,
          en 1921
          <a
            href="https://en.wikipedia.org/wiki/Auguste_Herbin"
            target="_blank"
            title="Auguste Herbin Wiki"
          >Auguste Herbin</a> créait l'alphabet plastique et presque un siècle plus tard le design numérique s'en est imprégné plus que jamais
          avec l'arrivée du flat design puis des abstractions de plus en plus nombreuses destinées à éveiller des stimulus visuels forts.
          <br />
        </p>
        <br />
        <Onscreen :moveY="0">
          <h2 pa-10 class="styloText">:: Co-conception et création</h2>
        </Onscreen>
        <v-layout row>
          <v-flex md6 pa-10>
            <Onscreen :moveY="0">
              <div>
                <h2 class="styloText">Système de conception</h2>
                <p class="styloText">
                  La singularité et l'originalité d'une image est le facteur mémoire d'un produit,
                  dans la conception de produits numériques l'écriture d'un
                  <span
                    class="surligneur"
                  >design system</span> accentue
                  et singularise sa perception dans le sens commun.
                  <a
                    href="https://bige-start.web.app/feeds/design-system-bige-un-premier-article-sur-les-glyphs-"
                    title="Design Système"
                    target="_blank"
                  >Voici un extrait de Design Système conçu pour Bige.</a>
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Storyboard</h2>
                <p class="styloText">
                  Établir un storyboard est une excéllente façon de nous projeter dans l'usage.
                  Depuis un simple texte ou une expression de storyboard sous forme de bande dessinée traduire l'usage de produits
                  au travers de différents personna est la pratique par excellence
                  <span
                    class="surligneur"
                  >pour définir et comprendre les cas d'usages.</span>
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Wireframes</h2>
                <p class="styloText">
                  Partant de zéro comme à l'issue d'un Sprint, la conception du wireframe minimise les impacts des produits.
                  C'est avec connaissance des dernières avancées et un savoir étendu en technologie que l'on conçois des squelettes
                  effiscients destinés à gagner du temps sur le prototypage et l'integration des interfaces.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Prototypage avancés</h2>
                <p class="styloText">
                  Le prototypage est un réel gain de temps sur la conception d'outils ou produits numériques aussi simple ou complexes soient-ils.
                  L'objectif est d'aboutir rapidement aux consituantes de futur interfaces utilisateur depuis un premier
                  <b>minimum viable product</b> jusqu'à la définition de produits fini.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Interface Utilisateur</h2>
                <p class="styloText">
                  Depuis la simple déclinaison de charte graphique établie jusqu'à la ré-écriture ou la refonte
                  d'interface existantes, la conception d'interface utilisateur est presque un art dès lors que l'on y intégre
                  les principes mathématiques régissant le sens de lecture commun. Accessibilité First, l'idée est d'élaborer
                  des
                  <span
                    class="surligneur"
                  >interfaces toujours plus simples</span> à l'usage.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Illustrations</h2>
                <p class="styloText">
                  <b>
                    <span class="surligneur">J'aime illustrer les livres pour enfants!</span>
                  </b>
                  <br />Comme l'interface, l'illustration et l'image sont au centre de toutes les attentions lorsque l'on découvre un produit.
                  Que ce soit pour toucher un stimuli empathique, définir un concept ou simplement passer un message,
                  je compose et illustre les concepts en adéquation avec les identités.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Motion Design</h2>
                <p class="styloText">
                  L'élément animé statique, interactif ou dynamique consiste à étblir un sentiment de confiance
                  en améliorant la qualité perçue du produit.
                  <br />Pour passer des messages clairs et adaptés aux nouveaux médias,
                  <b>
                    la
                    <span class="surligneur">réalisation de clips vidéo</span> animés améliore la comprhénsion et assure une plus large diffusion.
                  </b>
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Modélisation 3D et printing</h2>
                <p class="styloText">
                  Le prototypage d'objet physique est extrêmement coûteux, c'est pourquoi je me suis
                  <span
                    class="surligneur"
                  >spécialisé en modélisation</span>
                  dans l'objectif d'accélerer ce processus en réalisant des projections réélles en
                  <span
                    class="surligneur"
                  >impression 3D</span>.
                  Bige met également en relation un réseau de designer numériques capable de créer des images architecturales
                  et autres spécialistes de l'industrie aéronautique.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Idéations</h2>
                <p class="styloText">
                  En co-conception directement avec vos équipes, je considère l'idéation comme le coeur du progrès.
                  <b>
                    <span class="surligneur">C'est en se trompant que l'on apprend</span>
                  </b> Itérer et réitérer jusqu'à ce que la conception du produit atteigne ses objectifs.
                </p>
              </div>
            </Onscreen>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
  </div>
</template>
<script>
import Parallax from "@/components/UI/Parallax";
import Onscreen from "@/components/UI/OnScreen";

import headerUiUx from "@/assets/images/HP/header_uiux.jpg";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Parallax, Onscreen },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    fixedTab: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return true;
          case "md":
            return true;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      },
    },
  },
  data() {
    return {
      headerUiUx: headerUiUx,
    };
  },
  methods: {},
};
</script>